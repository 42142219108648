import client from './requestclient';

const points = {
    pointDetails: async (id) => {
        return await client({URL_PATH: `fetchpointdetails/${id}`, method: "GET"})
    },
    convertPointDirect: async (body) => {
        return await client({URL_PATH: "directpointstocashconversion", method: "POST", body: body});
    },
    convertCashToPointDirect: async (body) => {
        return await client({URL_PATH: "direct-cash-to-point-conversion", method: "POST", body: body});
    },
    acceptRequest: async (body) => {
        return await client({URL_PATH: "update-request-for-smartguarantor-status", method: "POST", body: body});
    },
    promoPoint: async () => {
        return await client({URL_PATH: `trip_promo_wallet_balance`, method: "GET"})
    },
}

export default points;