import client from "./requestclient";

// export const setTransactionPin = async pinPayload => {
//   return await client({
//     URL_PATH: "set-transaction-pin",
//     method: "POST",
//     body: pinPayload
//   });
// };


const trip = {
    autoSave: async (savePayload) => {
      return await client({ URL_PATH: `auto-save-for-trip`, method: "POST", body: savePayload });
    },
    autoSaveDeactivated: async (savePayload) => {
      return await client({ URL_PATH: `deactivate-auto-save`, method: "POST", body: savePayload });
    },
    voucherDailyCount: async () => {
      return await client({ URL_PATH: `user-voucher-daily-count`, method: "GET" });
    },
    lockOptions: async () => {
      return await client({ URL_PATH: `lock-smart-options`, method: "POST" });
    },
    lockSmartPoint: async (payload) => {
      return await client({ URL_PATH: `lock-smart-associate-wallet`, method: "POST", body: payload});
    },
    unLockSmartPoint: async (payload) => {
      return await client({ URL_PATH: `unluck-smart-lock`, method: "POST", body: payload});
    },
}

export default trip;
