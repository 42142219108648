<template>
   <div>
      <div class="holder">
         <div class="">
            <!-- <div class="top-text">
                  <h5>Welcome {{ userData.lastname}}</h5>
                  <p>Ready to deliver top-notch services today?</p>
            </div> -->

            <div class="dashboard-card-holder">
                  <div class="row">
                     <div class="col-md-6">
                        <div class="card-body">
                              <h6>Wallet Balance</h6>
                              <h3>{{ !visibility ? formattedBalance(availableBalance) : '************' }} <span @click="visibility = !visibility" style="margin-left: 10px; font-size: 20px;"><i class="fa fa-eye-slash" :class="[visibility ? 'fa-eye' : 'fa-eye-slash']"></i></span></h3>
                              <p class="tooltip-container">Expected 
                                 <span class="info-icon">!</span>
                                 <span class="tooltip-text">Expected earnings are the earning from the potential work done, will be moved to wallet after work is done.</span>
                              </p>
                              <small>{{ formattedBalance(expectedBalance) }}</small>

                              <div class="card-buttons">
                                 <button class="btn primary-btn" @click="generateVoucherLink()">Generate voucher</button>
                                 <!-- <div> -->
                                    <button class="btn secondary-btn" @click="withdraw()">Withdraw</button>
                                    <button class="btn secondary-btn" data-toggle="modal" data-target="#moneyConvertion" @click="convertionType = 'ctp'">Convert</button>
                                 <!-- </div> -->
                              </div>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="card-body" @click="pointsLogRoute()" style="cursor: pointer;">
                              <div class="d-flex justify-content-between">
                                 <h6>Main point</h6>
                                 

                                 <label class="switch" @click.stop="">
                                    <label class="autosave-text" for="autosave">Auto save</label>
                                    
                                    <input type="checkbox" v-model="autosave" @change.stop="saveForTrip()"  true-value="1" false-value="0">
                                    <span class="slider"></span>
                                 </label>

                                 <!-- <div class="custom-control custom-switch">
                                    
                                    <input type="checkbox" class="custom-control-input" id="autosave">
                                    <label class="custom-control-label" for="autosave">Auto save</label>
                                 </div> -->

                                 <!-- <div class="form-check form-switch">
                                    <label class="form-check-label" for="autosave">Auto save</label>
                                    <input type="checkbox" class="form-check-input" id="autosave">
                                 </div> -->
                              </div>
                              <h3>{{ toTwoDecimal(readyPoints) }} Points</h3>
                              <div class="d-flex justify-content-between">
                                 <div>
                                    <p class="tooltip-container">Promo points
                                       <span class="info-icon">!</span>
                                       <span class="tooltip-text">This are points earned during promo and is added to your main points after promo is over.</span>
                                    </p>
                                    <small>{{ promopointmain }} points</small>
                                 </div>
                                 <div>
                                    <p class="tooltip-container">Bonus points
                                       <span class="info-icon">!</span>
                                       <span class="tooltip-text">These are bonus points earned when you participate in one activity or the other.</span>
                                    </p>
                                    <small>{{ bonusBalance }} points</small>
                                 </div>
                              </div>

                              <div class="card-buttons">
                                 <button class="btn secondary-btn" @click.stop="openModalForPoint(); convertionType = 'ptc'">Convert</button>
                                 <!-- <button class="btn secondary-btn" @click="pointsLogRoute()">Points log</button> -->
                                 <!-- <div> -->
                                    <button class="btn secondary-btn" disabled>Transfer</button>
                                    <button :disabled="userData.lock_smart_wallet == 1" class="btn secondary-btn" @click.stop="moveToSmartLock()">Lock</button>
                                   
                                 <!-- </div> -->
                              </div>
                        </div>
                     </div>
                  </div>
            </div>


            <div class="transactions-holder">
               <div class="m-auto text-center mt-4" v-if="tLoading"><Loader/></div>
               <div class="">
                  <div class="">
                     <div class="recent-transactions">
                        <h5>Transactions</h5>
                        <div class="second-left-holder">
                           <div class="second-left">
                              <div class="d-flex justify-content-between align-items-center top-text">
                                 <h5>Transactions</h5>
                                 <h5>
                                    <router-link to="/user/dashboard/transactions" style="color: inherit; text-decoration: none;">
                                          See more...
                                    </router-link>
                                 </h5>
                              </div>
                              <!-- <div class="m-auto text-center mt-4" v-if="tLoading"><Loader/></div> -->
                              <div class="empty text-center mt-4 " v-if="propertyTransactions.length == 0 && !tLoading">No Records</div>
                              <div v-else class="transaction-lists mt-4" v-for="transaction in propertyTransactions.slice(0, 5)" :key="transaction.id">
                                 <div class="d-flex justify-content-between align-item-center mb-4" style="gap: 30px;">
                                    <div class="d-flex justify-content-between align-items-start tlh">
                                          <img src="../assets/icons/green-bg-arrow-down.svg" alt="">
                                          <div>
                                             <h6 v-if="transaction.description">{{transaction.description}} Pin-{{ transaction.pin }}</h6>
                                             <h6>{{formatDate(transaction.created_at)}}</h6>
                                          </div>
                                    </div>
                                    <div>
                                          <h6 class="amount"><td>₦{{formatAmount(transaction.amount)}}</td></h6>
                                    </div>
                                 </div>
                              </div>

                              <hr>

                              
                           </div>
                        </div>
                     </div>
                  </div>
                  
               </div>

               <div class="row">
                  <div class="col-md-6">
                     <div class="opportunity-desk-top commission-history-holder">
                        <!-- <div class="d-flex justify-content-between align-items-center opportuity-desk-text">
                              <h5>Commission history</h5>
                              <h5>See All</h5>
                        </div> -->
                        <div class="d-flex justify-content-between align-items-center top-text2 mb-3">
                           <h5>Commission history</h5>
                           <h5>
                              <!-- <router-link to="/user/dashboard/transactions" style="color: inherit; text-decoration: none;"> -->
                                    See more...
                              <!-- </router-link> -->
                           </h5>
                        </div>
                        <div class="m-auto text-center mt-4" v-if="cLoading"><Loader/></div>
                        <div class="empty text-center mt-4 " v-if="commissionHistory.length == 0 && !cLoading">No Commission</div>
                        <div v-else class="commission-lists" v-for="commission in commissionHistory.slice(0, 3)" :key="commission.id">
                           <div class="commission-type">
                              <h5>{{commission.description}}</h5>
                              <h5 class="d-none d-md-block">{{formatDate(commission.created_at)}}</h5>
                           </div>
                           <h4>Amount: <span>₦{{formatAmount(commission.amount)}}</span></h4>
                           <h4 class="d-block d-md-none">{{formatDate(commission.created_at)}}</h4>
                        </div>
                     </div>
                     <hr>
                  </div>
               
                  <div class="col-md-6">
                     <div class="opportunity-desk-top">
                            <div class="d-flex justify-content-between align-items-center opportuity-desk-text">
                                <h5>Opportunity Desk</h5>
                                <!-- <h5>See All</h5> -->
                            </div>
                            <div class="opp-category-holder">
                                <div class="opportunity-buttons" v-for="category in oppCategories" :key="category.id">
                                    <button @click=navigateToMoreOpp(category.id) class="bth">{{ category.type }}</button>
                                    
                                </div>
                            </div>
                            <div class="mt-4 inner-carousel" v-if="opportunityToLet.length > 0">
                                <div class="inner-slider" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                                    <div class="property-list" v-for="(item, index) of opportunityToLet.slice(0, 3)" :key="index">
                                        <div @click=navigateToMoreOpp(2)>
                                            <h6>{{ item.title }}</h6>
                                            <p>{{ item.description }}</p>
                                            <h5><span>{{ Number(item.amount).toLocaleString() }}</span>/Annually</h5>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="slide-button">
                                    <button class="btn" :class="[currentIndex == 0 ? 'active-slide' : '']"></button>
                                    <button class="btn" :class="[currentIndex == 1 ? 'active-slide' : '']"></button>
                                    <button class="btn" :class="[currentIndex == 2 ? 'active-slide' : '']"></button>
                                </div>
                            </div>
                     </div>
                  </div>
               </div>
            </div>



            <div class="modal fade" tabindex="-1" id="moneyConvertion">
               <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                  <div class="modal-content">
                        <div class="modal-header">
                           <h5 class="modal-title">Convert Cash to point</h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="transfer-content modal-body">
                           
                           <div class="form-group nairaSign">
                              <p class="currency-symbol">₦</p>
                              <input v-model="formattedAmount" @input="formatAmountCommas" class="form-control point-input" type="text" name="amounttoconvert" id="amounttoconvert" placeholder="0">
                           </div>
 
                           <div class="form-group convert-point-btn">
                              <button :disabled="converting" class="btn cancel" data-dismiss="modal" aria-label="Close">Cancel</button>
                              <!-- <button @click="convertCashToPoint()" :disabled="converting" class="convert btn">
                                    {{ converting ? 'Converting...' : 'Convert' }}
                              </button> -->
                              <button @click="openConfirmPin2()" class="convert btn">
                                 Continue
                              </button>
                           </div>
                        </div>
                  </div>
               </div> 
            </div>

            <!-- Point to cash convertion starts here -->
            <div class="modal fade" tabindex="-1" id="pointConvertion">
               <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                  <div class="modal-content">
                        <div class="modal-header">
                           <h5 class="modal-title">Convert point to cash</h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="transfer-content modal-body">
                           
                           <!-- <div class="form-group">
                              <input v-model="formattedAmount" @input="formatAmountCommas" class="form-control" type="text" name="amounttoconvert" id="amounttoconvert" placeholder="Amount to convert">
                           </div> -->

                           <div class="mb-4">
                              <p class="points-to-convert">Point balance: <span>{{ toTwoDecimal(readyPoints) }}</span></p>
                              <!-- <p class="points-to-convert">Point balance: <span>{{ Math.floor(readyPoints) || 0 }}</span></p> -->

                           </div>


                           <div class="form-group">
                              <input style="text-align: center !important; padding-left: 12px" v-model="selectedpoints" class="form-control point-input" type="number" name="points" id="points">
                           </div>

                           <p class="min-point">Minimum point to convert: 1</p>
                           <hr>


                           <div class="amount-breakdown" v-if="selectedpoints != 0">
                              <div class="d-flex justify-content-between mb-2">
                                 <h6>Total cash value</h6>
                                 <h6>{{ (selectedpoints * 1000).toLocaleString() }}</h6>
                              </div>
                              <div class="d-flex justify-content-between mb-2">
                                 <h6>Conversion charge</h6>
                                 <h6>5%</h6>
                              </div>
                              <div class="d-flex justify-content-between mb-2">
                                 <h6>Amount Receivable</h6>
                                 <h6>{{ (amountDeduction).toLocaleString() }}</h6>
                              </div>
                           </div>

                           <hr v-if="selectedpoints != 0">
 
                           <div class="form-group convert-point-btn">
                              <button :disabled="converting" class="btn cancel" data-dismiss="modal" aria-label="Close">Cancel</button>
                              <!-- <button @click="convertCashToPoint()" :disabled="converting" class="convert btn">
                                    {{ converting ? 'Converting...' : 'Convert' }}

                              </button> -->
                              <button @click="openConfirmPin()" :disabled="verifing" class="convert btn">
                                    <!-- {{ converting ? 'Converting...' : 'Continue' }} -->
                                    Continue
                              </button>
                           </div>
                        </div>
                  </div>
               </div> 
            </div>
            <!-- Point to cash convertion ends here -->

            <TransactionPinVerification @send-data="handleDataFromTransactionPinModal" />
         </div>
      </div>
   </div>
</template>


<script>

// import transactionPin from "../services/transactionPin";
import transactions from "../services/transactions";
import Loader from './Loader';
import currencyFormatter from "../helpers/currencyFormatter";
import formatDate from "../helpers/extractDate";
import opportunities from "../services/opportunityDesk";
import userCredentials from "../services/userCredentials";
import trip from "../services/trip";
import CF from "../helpers/currencyFormatter";
import points from "../services/points";
import realtor from "../services/realtor";
import TransactionPinVerification from "./TransactionPinVerification.vue";
import $ from "jquery";
// import partners from "../services/partners";

export default {
   name: "AssociateDashboard",
   components: {
    Loader,
    TransactionPinVerification,
  },
   // props: {
   //    emptyData: {
   //       type: Object,
   //       required: true,
   //    }
   // },
   
   data() {
      return {
         loading: false,
         visibility: true,
         tLoading: false,
         cLoading: false,
         verifing: false,
         loadingAutosave: false,
         converting: false,
         cashAmount: "",
         formattedAmount: "",
         autosave: '',
         userData: "",
         propertyTransactions: [],
         commissionHistory: [],
         userID: null,
         userToken: null,
         opportunityToLet: [],
         oppCategories: [],
         loadingProperty: false,
         convertionType: "",
         currentIndex: 0,
         interval: null,
         selectedpoints: 0,
         amountDeduction: 0,
         percentageAmount: 0,
         promopointmain: 0,
         receivedDataFromPinModal: "",
         propItem: [
            {
               title: "3 bedroom fully detached duplex1",
               description: "Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non ...",
               amount: 2000000,
            },
            {
               title: "2 bedroom fully detached duplex2",
               description: "Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non ...",
               amount: 2000000,
            },
            {
               title: "3 bedroom fully detached duplex3",
               description: "Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non ...",
               amount: 2000000,
            }
         ],
         availableBalance: 0,
         expectedBalance: 0,
         bonusBalance: 0,
         readyPoints: null,
         expectedPoints: null,
         transactionCount: null,
         // storedValue: localStorage.getItem("shelterUser") || "No Data"
      }
   },
   watch: {
      selectedpoints(newValue) {
         if (newValue) {
             this.getFivePercent();
         }
     },
     deep: true,
   },
   mounted() {

      let user = this.$store.getters.getUser;
      this.userData = this.$store.getters.getUser;
      this.userID = user.id;
      this.userToken = user.token;
      this.$root.$refs.user = this;

      // let newUser = JSON.parse(localStorage.getItem('smartUser'));
      // user = newUser;
      // this.userID = newUser.id;
      // this.userToken = newUser.token;
      // this.autosave = (user.auto_save_smart_point);
      this.autosave = localStorage.getItem("autosave");
     if (user.auto_save_smart_point == 1) {
      localStorage.setItem("autosave", '1');
     }

      this.getUserFullDetails()
      if (
         !user.passportfile ||
         user.passportfile.includes("https://") === false
      ) {
         this.getUserFullDetails();
      }


      this.startAutoSlide();
      this.opportunityByCategory();
      this.opportunityCategories();
      // this.autosave = (this.userData.auto_save_smart_point);
      // this.autosave = localStorage.getItem("autosave");
      // localStorage.setItem("autosave", '1');
      this.fetchPromoPoint();

      this.getDashboardSummary();
      this.getUserActivationDataAmount();

      this.fetchPropertyTransactionsHistory();

      this.fetchCommissionHistory();
   },

   created() {
      let user = this.$store.getters.getUser;
      if (user.auto_save_smart_point == 1) {
         localStorage.setItem("autosave", '1');
      }
      // let user = this.$store.getters.getUser;
      // this.userData = this.$store.getters.getUser;
      // this.userID = user.id;
      // this.userToken = user.token;
      // this.$root.$refs.user = this;

      // let newUser = JSON.parse(localStorage.getItem('smartUser'));
      // user = newUser;
      // this.userID = newUser.id;
      // this.userToken = newUser.token;
      // // this.autosave = (user.auto_save_smart_point);
      // this.autosave = localStorage.getItem("autosave");

      // console.log("User", user, this.autosave)

      // window.addEventListener("storage", this.refreshStorage);

      // I had to go through all this stress because the data returned when a user logs in
      // is not as complete as when you call the /getuserbyid GET api.

      // for example the returned login object does not have a city object and state object inside
      // of it, passportfile is name instead of url

      // but the object returned when you call getuserbyid has a city object, state object and the
      // passportfile property is a url and not a name.
      // if (
      //    !user.passportfile ||
      //    user.passportfile.includes("https://") === false
      // ) {
      //    this.getUserFullDetails();
      // }
      
   },
   beforeDestroy() {
     this.stopAutoSlide();
   //   localStorage.removeItem("autosave");
   },
//    beforeUnmount() {
//     window.removeEventListener("storage", this.refreshStorage);
//   },
   methods: {
      getFivePercent() {
         this.percentageAmount = ((this.selectedpoints * 1000) * 0.05);
         return this.amountDeduction = ((this.selectedpoints * 1000) - this.percentageAmount);
      },
      openModalForCashconvertion() {
         $("#moneyConvertion")
        .addClass("fade")
        .modal("show");
      },
      openModalForPoint() {
         $("#pointConvertion")
        .addClass("fade")
        .modal("show");
      }, 
      openConfirmPin() {
         if (this.selectedpoints < 1) { 
            return this.$toast.error("Point can not be less than 1");
         }
         if (this.selectedpoints == '0') {
            return this.$toast.error("Point can not be 0");
         }
         if (this.selectedpoints == '' || this.selectedpoints == null) {
            return this.$toast.error("Point can not be empty");
         }
         $("#pointConvertion")
        .removeClass("fade")
        .modal("hide");
         return this.$root.$refs.pin.openPinModal();
      }, 
      openConfirmPin2() {
         if (this.cashAmount == '0') {
            this.converting = false;
            return this.$toast.error("Amount can not be 0");
         }
         if (this.cashAmount == '') {
            this.converting = false;
            return this.$toast.error("Enter amount to convert");
         }
         if (this.availableBalance < this.cashAmount) {
            this.converting = false;
            return this.$toast.error("Wallet balance too low");
         }
         $("#moneyConvertion")
        .removeClass("fade")
        .modal("hide");
         return this.$root.$refs.pin.openPinModal();
      }, 
      handleDataFromTransactionPinModal(data) {
         this.receivedDataFromPinModal = data;
         if (this.convertionType == 'ptc') {
           return this.convertPointToCash();
         }
         if (this.convertionType == 'ctp') {
           return this.convertCashToPoint();
         }
        
      },
      async fetchPropertyTransactionsHistory() {
         if(this.userID != "" || this.userID == null) {
            try {
            this.tLoading = true;
            await transactions.propertyTransactions(this.userID)
            .then(data => {
               this.tLoading = false;
               // console.log("Datatatat", data);
               if (data.success) {
                     this.propertyTransactions = data.transactions;
               } else {
                     this.$toast.error(data.message);
               }
            })
            .catch((err) => {
               this.tLoading = false;
               console.log(err);
               // this.$toast.error("please check your network and refresh the page");
            })
            } finally {
               // this.tLoading = false;
               // console.log("Closed")
            }
         }
      },

      async fetchCommissionHistory() {
         if(this.userID != "" || this.userID == null) {
               try {
               // commission history
               this.cLoading = true;
               await transactions.commissionHistory(this.userID)
               .then(data => {
                  // console.log("commissiions", data);
                  this.cLoading = false;
                  if (data.success) {
                        this.commissionHistory = data.transactions;
                  } else {
                        this.$toast.error(data.error);
                  }
               })
               .catch((err) => {
                  this.cLoading = false;
                  console.log(err);
                  // this.$toast.error("please check your network and refresh the page");
               })
            } finally {
            this.tLoading = false;
            }
         }
      },
      formatAmountCommas() {
         let value = this.formattedAmount.replace(/,/g, ""); // Remove existing commas
         if (!isNaN(value) && value !== "") {
         this.cashAmount = value; // Store unformatted value
         this.formattedAmount = Number(value).toLocaleString(); // Add commas
         } else {
         this.formattedAmount = "";
         this.cashAmount = "";
         }
      },
      generateVoucherLink() {
         this.$router.push({path: `voucher`});
      },
      withdraw() {
         this.$router.push({path: `/user/dashboard/wallet`});
      },
      moveToSmartLock() {
         this.$router.push({path: `/user/smart-lock`});
      },
      pointsLogRoute() {
         this.$router.push({path: `/user/dashboard/point-logs`});
      },
      navigateToMoreOpp(id) {
         this.$router.push({path: `opportunity-more/${id}`});
      },
      formatDate(date) {
         return formatDate(date);
      },
      removeLeadingZero(str) {
         return str.replace(/^0+/, '');
      },
      async convertPointToCash() {
         const point = this.removeLeadingZero(this.selectedpoints);
         $("#pointConvertion")
            .addClass("fade")
            .modal("show");
         let body = {
            points: point,
         };

         body = JSON.stringify(body);
         try {
            await points.convertPointDirect(body).then(data => {
               // console.log("Point Convertion", data);
               this.selectedpoints = 0;
               if (data.success) {
                  this.$toast.success("Points convertion done");
                  this.selectedpoints = 0;
                  this.dashboardSummary();
                  $("#pointConvertion")
                  .removeClass("fade")
                  .modal("hide");
               }
               if (data.error) {
                  this.$toast.error(data.error);
                  this.selectedpoints = 0;
                  return this.$root.$refs.pin.closePinModal();
               }
            }) .catch((err) => {
               // this.$toast.error("please check your network and refresh the page");
               $("#pointConvertion")
               .removeClass("fade")
               .modal("hide");
               this.selectedpoints = 0;
               console.log(err);
            })
         } finally {
            // console.log("Finally")
         }
      },

      

      async convertCashToPoint() {
         $("#moneyConvertion")
        .addClass("fade")
        .modal("show");
         this.converting = true;
         if (this.cashAmount == '') {
            this.converting = false;
            return this.$toast.error("Enter amount to convert");
         }
         if (this.availableBalance < this.cashAmount) {
            this.converting = false;
            return this.$toast.error("Wallet balance too low");
         }
         let body = {
            amount: this.cashAmount,
         }
         try {
            await points.convertCashToPointDirect(JSON.stringify(body)).then((res) => {
               this.converting = false;
               if (res.success) {
                  this.$toast.success("Cash converted to point successfully");
                  this.getDashboardSummary();
                  $("#moneyConvertion")
                  .removeClass("fade")
                  .modal("hide");
               }
               if (res.error) {
                  this.$toast.error(res.error);
               }
            }).catch((err) => {
               this.converting = false;
               console.log(err);
               // this.$toast.error("please check your network and refresh the page");
            });
         } finally {
            // console.log("closed");
         }
         
      },
      
      // async setPinNow() {
      //    this.loadingPinSet = true;
      //    if (this.pin == '') {
      //       this.loadingPinSet = false;
      //       return this.$toast.error("Enter pin");
      //    }
      //    if (this.confirmPin == '') {
      //       this.loadingPinSet = false;
      //       return this.$toast.error("Enter confirm pin");
      //    }
      //    if (this.pin != this.confirmPin) {
      //       this.loadingPinSet = false;
      //       return this.$toast.error("Pins don't match");
      //    }
      //    const body = {
      //       pin: this.pin,
      //    }
      //    try {
      //       await transactionPin.setTransactionPin(JSON.stringify(body)).then((res) => {
      //          this.loadingPinSet = false;
      //          if (res.success) {
      //             this.$toast.success("SUCCESS, TRANSACTION PIN SET");
      //             this.setpinPassword = false;
      //             this.setpin = false;
      //          }
      //          if (res.error) {
      //             return this.$toast.error("ERROR:", res.error);
      //          }
      //       }).catch((err) => {
      //          console.log(err);
      //          // this.loadingPinSet = false;
      //       });
      //    } finally {
      //       // console.log("closed");
      //    }
      // },
      async opportunityByCategory() {
        if(this.userID != "" || this.userID != null) {
            this.loadingProperty = true;
            await opportunities.opportunityDeskByCategoryId(2).then(data => {
               // console.log("Opportunitiescategories:", data);
               this.loadingProperty = false;
               if (data.success) {
                  this.opportunityToLet = data.data;
               } else {
                  this.$toast.error(data.error);
               }
         }).catch((err) => {
               this.loadingProperty = false;
               console.log(err);
               // this.$toast.error("please check your network and refresh the page");
         });
        }
      }, 
      async opportunityCategories() {
        try {
         await opportunities.opportunityCategory().then(data => {
            // console.log("categories:", data);
            if (data.success) {
                this.oppCategories = data.data;
            } else {
                this.$toast.error(data.error);
            }
         }).catch((err) => {
            console.log(err);
         //   this.$toast.error("please check your network and refresh the page");
         });
        } finally {
         // console.log("closed");
        }
      },
      nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.propItem.length;
      },
      prevSlide() {
         this.currentIndex = (this.currentIndex - 1 + this.propItem.length) % this.propItem.length;
      },
      startAutoSlide() {
         this.interval = setInterval(this.nextSlide, 4000);
      },
      stopAutoSlide() {
         clearInterval(this.interval);
      },

      async getUserActivationDataAmount() {
         if(this.userID != "" || this.userID == null) {
            try {
            await realtor
            .fetchActivationData()
            .then(data => {
               if (data.activateddata) {
                  this.bonusBalance =
                  data.activateddata.status == 2 ? 0 : data.activateddata.amount;
               } else {
                  this.bonusBalance = 0;
               }
            })
            .catch(err => {
               console.log(err);
               // this.$toast.error("Please check your network and refresh page");
            });
         } finally {
            // console.log("closed")
         }
         }
        
      },

      async getUserFullDetails() {
         if(this.userID != "" || this.userID == null) {
            try {
            await userCredentials
            .userData(this.userID)
            .then(data => {
               // console.log("userrrrr", data)
               if (data.success) {
                  data.user.token = this.userToken;
                  this.$store.dispatch("saveUser", data.user);
               } else {
                  this.$toast.error(data.error);
               }
            })
            .catch((err) => {
               console.log(err);
               // this.$toast.error("please check your network and refresh the page");
            });
            } finally {
               // console.log("Closed");
            }
         }
         
      },
      async fetchPromoPoint() {
         try {
            await points
            .promoPoint()
            .then(data => {
               if (data.success) {
                  // console.log("points", data)
                  this.promopointmain = data.save_for_trip_balacne;
                  this.$store.dispatch("saveUser", data.user);
               } else {
                  this.$toast.error(data.error);
               }
            })
            .catch((err) => {
               console.log(err);
               // this.$toast.error("please check your network and refresh the page");
            });
         } finally {
            // console.log("closed")
         }
      },
      async saveForTrip() {
         this.loadingAutosave = true;
         if (this.autosave == '1') {
            // console.log("auto save", this.autosave)
            await trip.autoSave({}).then(data => {
               this.loadingAutosave = false;
               if (data.success) {
                  this.$toast.success("SUCCESS, AUTO SAVE ACTIVATED SUCCESSFULY");
                  localStorage.setItem("autosave", '1');
                  this.autosave = localStorage.getItem("autosave");
                  // this.autosave = 0;
                  // this.autosave++;
                  // return window.location.reload(true);
               }
               if (data.error) {
                  return this.$toast.error(data.error);
               }   
               
            }).catch((err) => {
               console.log(err);
               this.loadingAutosave = false;
                  // this.$toast.error("please check your network and refresh the page");
            });
         }

         if (this.autosave == '0') {
            // console.log("auto save", this.autosave)
            await trip.autoSaveDeactivated({}).then(data => {
               this.loadingAutosave = false;
               if (data.success) {
                  this.$toast.success("SUCCESS, AUTO SAVE DEACTIVATED SUCCESSFULY");
                  localStorage.setItem("autosave", '0');
                  this.autosave = localStorage.getItem("autosave");
                  // this.autosave = 1;
                  // this.autosave++;
                  // this.autosave = (this.userData.auto_save_smart_point).toString();
                  // return window.location.reload(true);
               }
               if (data.error) {
                  return this.$toast.error(data.error);
               }   
               
            }).catch((err) => {
                  this.loadingAutosave = false;
                  console.log(err);
                  // this.$toast.error("please check your network and refresh the page");
            });
         }
        
      },
      async getDashboardSummary() {
         if(this.userID != "" || this.userID == null) {
            try {
            await userCredentials
            .dashboardSummary(this.userID)
            .then(data => {
               if (data.success) {
                  this.availableBalance = data.readywallet;
                  this.expectedBalance = data.expectedwallet;
                  this.transactionCount = data.transactions;
                  this.readyPoints = data.readypoint;
                  this.expectedPoints = data.expectedpoint;
               } else {
                  this.$toast.error(data.error);
               }
            })
         .catch((err) => {
            console.log(err);
            // this.$toast.error("please check your network and refresh the page");
         });
            } finally {
               // console.log("closed")
            }
         }
      },
      formattedBalance(amount) {
         return CF.naira.format(amount);
      },
      toTwoDecimal(value) {
         return CF.toTwoDecimal(value);
      },
      formatAmount(value) {
        return currencyFormatter.toTwoDecimal(value);
      },
   },
}
</script>


<style scoped lang="scss">
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$sheltaBlack: #2B3352;

.holder {
   background: #ffffff;
   padding: 40px 20px;
}

.top-text {
   width: 100% !important;
   h5 {
      font-family: Lato;
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      color: #2B3352;
   }
   p {
      font-family: Lato;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #555C74;
   }
}


.card-body {
   border: 1px solid #E9EAEB;
   padding: 20px;
   border-radius: 10px;
   margin-top: 20px;
   h6 {
      font-family: Lato;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0%;

   }
   h3 {
      font-family: Lato;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #2B3352;
   }
   p {
      font-family: Lato;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #555C74;
      position: relative;
      width: fit-content;

   }
   small {
      font-family: Lato;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #2B3352;
   }
}

.card-buttons {
   margin-top: 20px;
   button {
      margin-right: 10px;
      margin-bottom: 15px;
      outline: 0;
      box-shadow: none;
   }
}
.primary-btn {
   font-family: Lato;
   font-weight: 400;
   font-size: 14px;
   background: #0033EA;
   color: #ffffff;
   
}

.secondary-btn {
   font-family: Lato;
   font-weight: 400;
   font-size: 14px;
   border: 1px solid #D5D7DA;
   color: #414651;
}


.tooltip-text {
   visibility: hidden;
   width: 200px;
   background-color: #333;
   color: #fff;
   text-align: center;
   border-radius: 5px;
   padding: 5px 10px;
   position: absolute;
   z-index: 10;
   bottom: 125%; /* Position the tooltip above the element */
   left: 83px;
   transform: translateX(-50%);
   opacity: 0;
   transition: opacity 0.3s;
   text-align: left;
   // white-space: nowrap;
}

 /* Tooltip arrow */
 .tooltip-text::after {
   content: '';
   position: absolute;
   top: 100%;
   left: 50%;
   transform: translateX(-50%);
   border-width: 5px;
   border-style: solid;
   border-color: #333 transparent transparent transparent;
}

.info-icon {
   border: 1px solid #555C74;
   padding: 0px 6px;
   border-radius: 50px;
   margin-left: 2px;
   color: #555C74;
}


.tooltip-container {
   position: relative;
   // display: inline-block;
   cursor: pointer;
}


/* Show tooltip on hover */
.tooltip-container:hover .tooltip-text {
   visibility: visible;
   opacity: 1;
}



 /* Toggle Switch */
.switch {
   position: relative;
   display: inline-block;
   width: 50px;
   height: 24px;
   cursor: pointer;
}

.switch input {
   opacity: 0;
   width: 0;
   height: 0;
}

.slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ccc;
   transition: 0.4s;
   border-radius: 24px;
}

.slider:before {
   position: absolute;
   content: "";
   height: 18px;
   width: 18px;
   left: 3px;
   bottom: 3px;
   background-color: white;
   transition: 0.4s;
   border-radius: 50%;
}

input:checked + .slider {
   background-color: #4CAF50;
}

input:checked + .slider:before {
   transform: translateX(26px);
}

.autosave-text {
   position: absolute;
   left: -63px;
   top: 0px;
   font-family: Lato;
   font-weight: 400;
   font-size: 14px;
   color: #555C74;
   // line-height: 20px;

}


.second-left-holder {
    background:$white;
    border-radius: 10px;
   //  padding: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
   //  width: 100%;
}
.second-left {
    .top-text {
        h5 {
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            color: $asheGrey;
        }
        h5:first-child {
            font-weight: 500;
        }
        h5:nth-child(2) {
            font-weight: 700;
            color: $primary;
        }
    }
}

.top-text2 {
   h5 {
      font-family: Lato;
      font-size: 16px;
      font-weight: 500;
      color: $asheGrey;
   }
   h5:first-child {
      font-weight: 500;
   }
   h5:nth-child(2) {
      font-weight: 700;
      color: $primary;
   }
}
.tlh {
    gap: 10px;
    div {
        h6 {
            font-family: Lato;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: $asheGrey;
            margin-bottom: 0;
            white-space: nowrap; /* Prevents text from wrapping */
            overflow: hidden; /* Hides overflowing text */
            text-overflow: ellipsis; /* Adds "..." */
            width: 350px;
        }
    }
}

.amount {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: $asheGrey;
    margin-bottom: 0;
}

.transactions-holder {
   margin-top: 40px;
}

.recent-transactions {
   // padding: 16px;
}


// opporturnity desk starts
.opportunity-desk-top {
    background: $white;
   //  padding: 16px;
    border-radius: 10px;
}
.opp-category-holder {
    // display: ruby-text;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
}
.opportunity-buttons {
    // display: flex;
    // flex-direction: row;
    // gap: 10px;
    a {
        margin-right: 7px;
        color: inherit;
        text-decoration: none;
        color: inherit;
        display: contents;
    }
    button {
        border: 1px solid #A1A2A3;
        background-color: transparent;
        color: $asheGrey;
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        text-align: center;
        height: 40px;
        // width: fit-content;
        border-radius: 50px;
        padding: 10px 12px;
        // margin-right: 7px !important;
        margin-bottom: 10px;
    }
    button:last-child {
        margin-right: 0;
    }
    button:hover {
        background: $primary;
        color: $white;
        border: none;
    }
}

.opportuity-desk-text {
    margin-bottom: 20px;
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        color: $asheGrey;
    }
    h5:first-child {
        font-weight: 500;
    }
    h5:nth-child(2) {
        font-weight: 700;
        color: $primary;
        cursor: pointer;
    }
}

.inner-carousel {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: auto;
    overflow: hidden;
}
.inner-slider {
   display: flex;
   transition: transform 0.5s ease;
}
.property-list {
    min-width: 100%;
    box-sizing: border-box;
    h6, p, h5 {
        font-family: Lato;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #2B3352;
    }
    h6 {
        font-weight: 600;
    }
    p {
        font-weight: 400;
        color: $asheGrey;
        margin-bottom: 10px !important;
    }
    h5 {
        font-weight: 400;
        text-align: justified;
        color: $primary;
        span {
            font-weight: 600;
        }
    }
}

.slide-button {
    text-align: center;
    button {
        background: blue;
        width: 8px;
        height: 8px;
        border-radius: 50px;
        padding: inherit;
        margin: 0 5px;
    }
}

.active-slide {
    border: 4px solid $primary;
    padding: 3px !important;
    background: $white !important;
}
// opporturnity desk ends

// commission starts
.commission-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    h5 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: $asheGrey;
    }
    h5:first-child{
        background: #F8F8F8;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border-left: 2px solid $yellow;
        padding: 4px 8px;
        width: 260px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.commission-lists {
    margin-bottom: 20px;
    border-bottom: 1px solid #EBECEC;
    h4 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: $asheGrey;
        // margin-top: 5px;
    }
}
.commission-lists:last-child {
    border-bottom: none;
}
// commission ends


.convert-point-btn {
   display: flex;
   justify-content: right;
   button {
      outline: 0;
      box-shadow: none;
      font-family: Lato;
      font-weight: 400;
      font-size: 14px;
   }
   .cancel {
      border: 1px solid #D5D7DA;
      color: #414651;
      background: white;
      margin-right: 10px;
   }
   .convert {
      background: #0033ea;
      color: #ffffff;
   }
}

input {
   height: 48px;
   box-shadow: none !important;
   outline: 0 !important;

}

.point-input {
   border: none;
   font-family: Lato;
   font-weight: 400;
   font-size: 72px;
   line-height: 90px;
   text-align: left;
   padding-left: 70px;
   height: 80px;
}

.points-to-convert {
   border: 1px solid #E9EAEB;
   background: #FAFAFA;
   font-family: Lato;
   font-weight: 500;
   font-size: 14px;
   line-height: 20px;
   border-radius: 8px;
   padding: 4px 10px;
   text-align: center;
   width: fit-content;
   margin: auto !important;
   color: #555C74;
   span {
      color: #555C74;
      font-weight: bold;
   }

}
.amount-breakdown {
  h6 {
    font-size: 16px;
    font-weight: 400;
  }
}
.min-point {
   font-family: Lato;
   font-weight: 500;
   font-size: 14px;
   line-height: 20px;
   color: #555C74;
   text-align: center;
   margin-bottom: 40px !important;
}

.nairaSign {
   position: relative;
   display: flex;
   align-items: center;
   color: #495057;
   // width: 150px;
}

.currency-symbol {
  position: absolute;
  left: 10px;
  font-size: 72px;
  color: #333;
  font-weight: bold;
  padding-top: 10px;
}

@media screen and (max-width: 599px) { 
   .card-buttons {
      button {
         width: 100%;
         margin-bottom: 5px;
      }
   }
   .tlh {
    gap: 10px;
      div {
         h6 {
               width: 100px;
         }
      }
   }
   .opportuity-desk-text {
      margin-top: 30px;
   }
   .point-input {
      font-size: 35px;
      padding-left: 44px;
   }
   .currency-symbol {
      font-size: 35px;
      padding-top: 4px;
   }
}



</style>