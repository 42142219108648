<template>
    <div>
        <div>
            <div class="modal fade" tabindex="-1" id="transactionPin">
               <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                  <div class="modal-content">
                        <div class="modal-header">
                           <div>
                            <h5 class="modal-title">Transaction Pin</h5>
                            <p>To proceed, you need to provide your transaction pin</p>
                           </div>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="transfer-content modal-body">
                           
                           <div class="form-group">
                              <input v-model="pinNumber" min="0" max="9999" maxlength="4" oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4);" class="form-control point-input" type="number" name="pin" id="pin" placeholder="0">
                           </div>
 
                           <div class="form-group verify-btn">
                              <button :disabled="verifing" class="btn cancel" data-dismiss="modal" aria-label="Close">Cancel</button>
                              <button @click="verifyTransactionPin()" :disabled="verifing" class="verify btn">
                                    {{ verifing ? 'Verifing...' : 'Verify' }}
                              </button>
                           </div>
                        </div>
                  </div>
               </div> 
            </div>
        </div>
    </div>
</template>



<script>
import $ from "jquery";
import transactionPin from "../services/transactionPin";
export default {
    name: "TransactionPinVerification",
    // props: {
    //     emptyData: {
    //         type: Object,
    //         required: true,
    //     }
    // },
    data() {
        return {
            loading: false,
            verifing: false,
            pinNumber: null,
            // parentData: ""
        }
    },
    created() {
        this.$root.$refs.pin = this;
      //   this.$root.$refs.pin2 = this;
    },
    methods: {
        openPinModal() {
            $("#transactionPin")
            .addClass("fade")
            .modal("show");
        },
        closePinModal() {
            $("#transactionPin")
            .removeClass("fade")
            .modal("hide");
        },
        async verifyTransactionPin() {
         this.verifing = true;
         if (this.pinNumber == '') {
            this.verifing = false;
            return this.$toast.error("Enter pin");
         }
        
         const body = {
            pin: this.pinNumber,
         }
         try {
            await transactionPin.verifyPin(JSON.stringify(body)).then((res) => {
               this.verifing = false;
               if (res.success) {
                  // this.$toast.success("CURRECT PIN");
                  // this.parentData = "success"
                  this.$emit("send-data", "success");
                  this.pinNumber = "";
                  $("#transactionPin")
                  .removeClass("fade")
                  .modal("hide");
               }
               if (res.error) {
                  // $("#transactionPin")
                  // .removeClass("fade")
                  // .modal("hide");
                  this.pinNumber = "";
                  return this.$toast.error(res.error);
               }
                }).catch((err) => {
                  this.pinNumber = "";
                  console.log(err);
                  // this.loadingPinSet = false;
                });
            } finally {
                // console.log("closed");
            }
        },
    },
}
</script>

<style scoped lang="scss">


input {
   height: 48px;
   box-shadow: none !important;
   outline: 0 !important;

}


.verify-btn {
   display: flex;
   justify-content: right;
   button {
      outline: 0;
      box-shadow: none;
      font-family: Lato;
      font-weight: 400;
      font-size: 14px;
   }
   .cancel {
      border: 1px solid #D5D7DA;
      color: #414651;
      background: white;
      margin-right: 10px;
   }
   .verify {
      background: #0033ea;
      color: #ffffff;
   }
}

.point-input {
      border: none;
      font-family: Lato;
      font-weight: 400;
      font-size: 72px;
      line-height: 90px;
      text-align: center;
      height: 80px;
   }
</style>