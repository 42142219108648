import client from "./requestclient";

const transactionPin = {
    setTransactionPin: async (pinPayload) => {
      return await client({ URL_PATH: `set-transaction-pin`, method: "POST", body: pinPayload });
    },
    updateTransactionPin: async (pinPayload) => {
      return await client({ URL_PATH: `update-transaction-pin`, method: "POST", body: pinPayload });
    },
    verifyPin: async (pinPayload) => {
      return await client({ URL_PATH: `verify-transaction-pin`, method: "POST", body: pinPayload });
    },
    updateAppPassword: async (payload) => {
      return await client({ URL_PATH: `update-login-password`, method: "POST", body: payload });
    },
}

export default transactionPin;
